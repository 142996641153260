.card-homepage-humain {
    background-color: rgb(249, 246, 243);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.65);
    height: min-content;
    width: 90vw;
    margin: 10px auto 10px auto;
    border-radius: 10px;
    padding: 10px;
    & hr {
        width: 50%;
        margin: 0 auto;
        color: white;
    }
    & h2 {
        text-align: center;
        font-size: 2em;
        color: $purple;
        margin: 10px;
        padding: 10px;
        font-family: "Tempus-Sans-ITC";
    }
    & h3 {
        font-size: 1.4em;
        font-family: "Tempus-Sans-ITC";
    }
    & ul li {
        margin: 10px 0;
    }
    & ul li span {
        color: $color-primary;
        text-decoration: underline;
        line-height: 200%;
    }
}

.card-homepage_other {
    height: fit-content;
    min-height: 80vh;
    font-size: 1.2em;
    padding-top: 40px;
    & h1 {
        color: white;
        font-size: 1.2em;
        font-family: "Tempus-Sans-ITC";
        @include respond-to("md") {
            font-size: 1.8em;
        }
    }
    & h2 {
        color: $color-secondary;
        font-family: "Tempus-Sans-ITC";
    }
    & .div_header {
        & p {
            margin: 0;
        }
        & .listing-gps {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            margin: auto;
        }
    }
    & .div_header-title a {
        margin: 10px;
        padding: 10px;
    }
    .div-card {
        padding: 0;
        & a {
            text-decoration: none;
        }
    }
}

.card-homepage {
    @extend .card-homepage_other;
    height: fit-content;
    min-height: auto;
    & h1 {
        color: white;
        font-size: 1.2em;
        font-family: "Tempus-Sans-ITC";
        @include respond-to("md") {
            font-size: 1.8em;
        }
    }
    & h2 {
        color: $color-secondary;
        font-family: "Tempus-Sans-ITC";
    }
}

.card-homepage_white {
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.65);

    min-height: inherit;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @include respond-to("md") {
        flex-direction: row;
        justify-content: space-around;
        font-size: 1.2em;
    }

    & h2 {
        color: $color-tertiary;
        font-family: "Tempus-Sans-ITC";
        text-align: center;
        margin-bottom: 40px;
    }
    & .div_header {
        padding: 30px;
    }
    & .div_header-card-left {
        background: #1d4f45;
        box-shadow: 0 0 15px 0 rgba(183, 128, 25, 0.65);
        border-radius: 20px;
        background: linear-gradient(
            90deg,
            rgba(29, 79, 69, 1) 0%,
            rgba(157, 197, 136, 1) 100%
        );
        margin: 40px 0;
        padding: 20px;
        transform: translateX(-25%);
        width: 75%;
        @include respond-to("md") {
            width: 25%;
            transform: translateX(-25%);
        }
        @include respond-to("lg") {
            width: 30%;
            transform: translateX(-35%);
        }
    }
    & .div_header-card-left img {
        border-radius: 20px;
        filter: grayscale(50%);
        width: auto;
        max-width: 75vw;
        @include respond-to("md") {
            max-width: 25vw;
        }
        @include respond-to("lg") {
            max-width: 30vw;
        }
        object-fit: cover;
    }
    & .div_header-card-right {
        @extend .div_header-card-left;
        background: linear-gradient(
            -90deg,
            rgba(29, 79, 69, 1) 0%,
            rgba(157, 197, 136, 1) 100%
        );
        transform: translateX(25%);
        width: 75%;
        @include respond-to("md") {
            width: 25%;
            transform: translateX(25%);
        }
        @include respond-to("lg") {
            width: 30%;
            transform: translateX(35%);
        }
        @include respond-to("xl") {
            width: 30%;
            transform: translateX(45%);
        }
        @include respond-to("xxl") {
            width: 30%;
            transform: translateX(55%);
        }
    }
}
.card-homepage_green {
    padding-top: 40px;
    @extend .card-homepage_white;
    background: #d6efc5;
    background: linear-gradient(90deg, #d6efc5 0%, #8cb686 100%);

    & h1 {
        color: $color-tertiary;
        font-size: 1.3em;
        text-align: center;
        font-family: "Tempus-Sans-ITC";
        @include respond-to("md") {
            font-size: 2em;
        }
    }
    & h2 {
        padding: 10px;
    }
    & p {
        padding: 20px;
        line-height: 120%;
    }
    & .div_header-card-content h2 {
        color: $color-primary;
    }
    & .div_header-card-left {
        background: white;
        box-shadow: 0 0 15px 0 rgba(183, 128, 25, 0.65);
        border-radius: 20px;
        margin: 40px 0;
        transform: translateX(-25%);
        width: 75%;
        @include respond-to("md") {
            width: 25%;
            transform: translateX(-15%);
        }
    }
    & .div_header-card-right {
        @extend .div_header-card-left;

        transform: translateX(25%);
        width: 75%;
        @include respond-to("md") {
            width: 25%;
            transform: translateX(15%);
        }
    }
}

.div_header-card-content {
    padding: 30px;
}
.card-title {
    font-size: 1.4em;
    color: white;
    text-align: center;
    margin: 30px;
    padding: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.div-card-visite {
    margin: 60px auto;
    text-align: center;
    & img {
        border-radius: 20px;
        border: 5px solid white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.65);
    }
}

.text-center {
    text-align: center;
}

.img_tenor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    & img {
        width: 100%;
        max-width: 300px;
        margin: 15px;
        height: auto;
        border-radius: 20px;
        border: 5px solid white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.65);
    }
}
