.main-homepage {
    margin: calc(30vh + 220px) 0 0 0;
    transition: all 1s ease-in-out;
    padding-bottom: 30px;
    @include respond-to("md") {
        margin: calc(30vh + 140px) 0 0 0;
    }
    & h1 {
        text-align: center;

        font-size: 1.4em;
        color: white;
    }
}
.div_header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @include respond-to("lg") {
        flex-direction: row;
    }
    & .div_header-title {
        width: 95%;
        padding: 20px;
        text-align: center;
        @include respond-to("lg") {
            width: 45%;
            padding: 100px;
        }
        & h1 {
            font-size: 2em;
            color: white;
        }
        & small {
            text-align: center;
            width: 45%;
            font-size: 1em;
            color: white;
            line-height: 2em;
        }
    }
    & .div_header-card {
        & img {
            object-fit: cover;
            border-radius: 25px 25px 0 0;
            width: 100%;
            max-width: 90vw;
            margin-bottom: -5px;
            max-height: 450px;
            border: 5px solid white;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.65);
        }
        max-width: 90vw;
        @include respond-to("md") {
            margin: 50px;
            width: 550px;
            max-width: 60vw;
        }
        @include respond-to("lg") {
            margin: 70px;
            width: 750px;
            max-width: 80vw;
        }
        margin: 40px;
        text-align: center;
        border-radius: 30px 30px 0 0;
    }
    &-card-content ul li {
        margin: 10px 0;
    }
}
