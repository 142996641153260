@font-face {
    font-family: "ABeeZee";
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/font/abeezee-v14-latin-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("../../assets/font/abeezee-v14-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../../assets/font/abeezee-v14-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../../assets/font/abeezee-v14-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../../assets/font/abeezee-v14-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../assets/font/abeezee-v14-latin-regular.svg#ABeeZee")
            format("svg"); /* Legacy iOS */
}

@font-face {
    font-family: "Savoye-LET-Plain";
    src:
        local(""),
        /* IE6-IE8 */ url("../../assets/font/Savoye-LET-Plain.woff2")
            format("woff2"),
        /* Modern Browsers */ url("../../assets/font/Savoye-LET-Plain.ttf")
            format("truetype");
}

@font-face {
    font-family: "Tempus-Sans-ITC";
    src:
        local(""),
        /* Modern Browsers */ url("../../assets/font/TRTempusSansITC.ttf")
            format("truetype");
}
body {
    background-image: url(../../assets/bg/bg1920-lestresorsdelharmoniebylitiboost.jpg);
    background-position: top center;
    background-size: 150vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    height: 100vh;
    padding: 0;
    margin: 0;
    font-family: "ABeeZee";
    @include respond-to("sm") {
        background-image: url(../../assets/bg/bg1920-lestresorsdelharmoniebylitiboost.jpg);
        background-size: 220vh;
    }
    @include respond-to("lg") {
        background-image: url(../../assets/bg/bg1920-lestresorsdelharmoniebylitiboost.jpg);
        background-size: cover;
    }
}

.opacity-100 {
    opacity: 100;
    transition: all 1s ease-in-out;
}
main {
    background: #1d4f45;
    background: linear-gradient(
        90deg,
        rgba(29, 79, 69, 1) 0%,
        rgba(157, 197, 136, 1) 100%
    );
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
}

footer {
    background: rgb(6, 33, 28);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.65);

    color: rgba(157, 197, 136, 1);
    padding: 10px;
    text-align: center;
    & .footer-link {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        padding: 30px 60px;
    }
    & div img {
        margin-top: -10px;
        width: 50px;
    }
    & hr {
        width: 50%;
        margin: 20px auto;
        color: white;
    }
    & .name_litiboost {
        font-family: "Savoye-LET-Plain";
        font-size: 2em;
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    & a {
        color: $color-secondary;
        margin: 10px;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
    }
    & a:hover {
        color: rgb(207, 237, 191);
    }
}

.btn-outline {
    border: 2px solid $color-tertiary;
    border-radius: 5px;
    background-color: white;
    color: rgba(157, 197, 136, 1);
    transition: all 0.5s ease-in-out;
    padding: 10px 5px;
    margin: 10px auto;
    text-align: center;
    width: fit-content;
    & a {
        color: $color-tertiary;
        transition: all 0.5s ease-in-out;
        text-decoration: none;
    }
    &:hover {
        background-color: rgba(157, 197, 136, 1);
        & a {
            color: white;

            text-decoration: none;
        }
    }
}
.pt-5 {
    padding-top: 20px;
}
.ms-3 {
    margin-left: 20px;
}
.cvg {
    padding: 50px;
    background-color: white;

    & h2 {
        font-family: "Tempus-Sans-ITC";
        font-size: 2em;
        text-align: center;
        margin: 20px auto;
        color: $color-tertiary;
    }
    & a {
        text-decoration: none;
        color: $color-primary;
    }
    & a:hover {
        text-decoration: none;
        color: $color-secondary;
    }
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.widget-phone {
    position: fixed;
    bottom: -1px;
    right: -7px;
    z-index: 1000;
    font-size: 9px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 35px;
    width: 110px;
    transition: all 0.5s ease-in-out;
    background-color: $color-primary;
    border-radius: 20px 0 0 0;
    border-top: 2px solid $color-primary;
    border-left: 2px solid $color-primary;
    padding-right: 0;
    & :hover {
        background-color: white;
        color: $color-primary;
        border-radius: 20px 0 0 0;
        transition: all 0.5s ease-in-out;
    }
    & a {
        text-decoration: none;
        color: $color-primary;
        font-size: 1.5em;
        padding: 10px;
        border-radius: 20px 0 0 0;
        color: white;
        & svg {
            width: 10px;
            height: 10px;
            padding-right: 5px;
        }
    }
}

.p-5 {
    padding: 50px 30px;
    font-size: 16px;
    line-height: 150%;
}
.text-white {
    color: white;
    & p {
        line-height: 150%;
        @include respond-to("md") {
            font-size: 1.2em;
        }
    }
}
