@use "sass:meta";
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(15px);
    }
    10% {
        opacity: 0;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@mixin fade-in-nav-link($args...) {
    @each $name, $seconde in meta.keywords($args) {
        .fade-#{$name} {
            animation-name: fadeIn;
            animation-timing-function: linear;
            animation-duration: $seconde;
        }
    }
}
@include fade-in-nav-link(
    $l0: 0,
    $l1: 100ms,
    $l2: 200ms,
    $l3: 300ms,
    $l4: 400ms
);
