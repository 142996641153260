header {
    padding: 0;
    margin: calc(20vh) 0 0 0;
    width: 100vw;
    z-index: 99;
    top: 0;
    position: sticky;
    height: fit-content;
    @include respond-to("sm") {
        margin: calc(10vh) 0 0 0;
        height: 100px;
    }
}
.sidebar {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}

.is-sticky {
    position: fixed;
    top: 0px;
    z-index: 99;
    margin: 0;
    background: white;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    transition: background-color 1s ease-in-out;
    height: 40px;
    @include respond-to("md") {
        height: 60px;
    }
    & .navbar-brand {
        font-size: 1.2em;
        margin: 5px auto 0 auto;
        transition: all 500ms ease-in-out;
        text-shadow: white 1px 1px 2px;
        @include respond-to("md") {
            font-size: 1.6em;
            margin: 10px auto 0 auto;
        }
        & span {
            width: fit-content;
        }
    }
    & .navbar-brand .right {
        padding-left: 3px;
    }
}

.navbar-brand {
    font-size: 2.5em;
    transition: all 1s ease-in-out;
    color: $purple;
    font-family: "Tempus-Sans-ITC";
    /* color | offset-x | offset-y | blur-radius */
    text-shadow: white 2px 4px 3px;
    text-align: center;
    transition: all 500ms ease-in-out;
    & span {
        width: fit-content;
    }

    @include respond-to("sm") {
        font-size: 3em;
    }
    @include respond-to("lg") {
        font-size: 4.5em;
    }
}

.navbar-brand .left {
    display: inline-block;
    transform: translateX(-20vw);
    opacity: 0;
    margin-top: 3px;
    animation: rotate-init-left 1s ease-in-out forwards;
}

@keyframes rotate-init-left {
    0% {
        transform: translateX(-20vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.navbar-brand .right {
    display: inline-block;
    transform: translateX(20vw);
    padding-left: 10px;
    opacity: 0;
    margin-top: 3px;
    animation: rotate-init-right 1s ease-in-out forwards;
}
@keyframes rotate-init-right {
    0% {
        transform: translateX(20vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.is-active-nav {
    color: $green;
}
.pointer {
    cursor: pointer;
}
.scroll-to-bottom {
    display: block;
    width: fit-content;
    height: 70vh;
    font-size: 4em;
    margin: -15vh auto 0 auto;
    z-index: 999;
    @include respond-to("sm") {
        font-size: 6em;
        margin: -10vh auto 0 auto;
    }
    @include respond-to("md") {
        margin: 10vh auto 0 auto;
    }
    @include respond-to("lg") {
        margin: 30vh auto 0 auto;
    }
    @include respond-to("xl") {
        margin: 40vh auto 0 auto;
    }
    & div {
        animation: jumpInfinite 1.5s infinite;
        opacity: 1;
        cursor: pointer;
        border-radius: 20px;
        color: $purple;
        background-color: transparent;
        height: fit-content;
        margin: auto;
        text-align: center;
        width: auto;
    }
}

.is-hidden {
    display: none;
}

@keyframes jumpInfinite {
    0% {
        padding-top: 20px;
        padding-bottom: 0;
        color: white;
        background-color: $purple;
        opacity: 100%;
    }
    50% {
        padding-top: 00px;
        padding-bottom: 20px;
        color: white;
        background-color: transparent;
        opacity: 100%;
    }
    100% {
        padding-top: 20px;
        padding-bottom: 0;
        color: white;
        background-color: $purple;
        opacity: 100%;
    }
}

/* 
    NAVBAR
*/

nav {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transform: translateX(-100vw);
    transition: all 0.5s ease-in-out;
    z-index: 99;
    width: 99vw;
    height: fit-content;
    background-image: url(../../assets/bg/bg1920-lestresorsdelharmoniebylitiboost.jpg);
    background-position: top center;
    background-size: 80vh;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    border-radius: 0 0 50px 0;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    @include respond-to("sm") {
        background-image: url(../../assets/bg/bg1920-lestresorsdelharmoniebylitiboost.jpg);
        background-size: 100vh;
    }
    @include respond-to("lg") {
        background-image: url(../../assets/bg/bg1920-lestresorsdelharmoniebylitiboost.jpg);
        background-size: cover;
    }
}
.nav-icon {
    display: block;
    position: absolute;
    left: calc(-100px);
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    color: white;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;
    font-size: 0.5em;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    @include respond-to("md") {
        font-size: 1em;
    }
}
.display-nav-icon {
    left: 10px;
    transition: all 0.5s ease-in-out;
}

.openMenu {
    background-color: $color-primary;
    transition: all 0.5s ease-in-out;
    margin-top: 13px;
    transform: translateX(0);

    @include respond-to("md") {
        width: 50vw;
        margin-top: 20px;
    }
    & span {
        color: $color-primary;
    }
}

.nav-link {
    border: 2px solid $color-secondary;
    border-radius: 5px;
    background-color: white;
    color: $color-primary;
    transition: all 0.5s ease-in-out;
    padding: 10px 5px;
    margin: 10px auto;
    @include respond-to("md") {
        padding: 10px;
        margin: 10px;
        font-size: 1.1em;
    }
}
.nav-link:hover {
    background-color: $green;
    color: white;
    transition: all 0.5s ease-in-out;
}
.nav-link:link {
    text-decoration: none;
}
